<template>
  <div>

    <div style="margin-bottom: 20px;display: flex;">
      <div>
        <el-image
            style="width: 350px; height: 200px;border-radius: 10px"
            :src="video.cover"
            :preview-src-list="[video.cover]"
        >
        </el-image>
      </div>
      <div style="display: flex; flex-direction: column;margin-left: 20px">
        <h1 style="padding: 20px 0">{{ video.title }}</h1>

        <div style="display: flex;padding: 20px 0;font-size: 14px; color: #b2b2b2">
          <span>{{ video.pubdate }} 发布</span>
          <span style="margin-left: 30px">  {{ video.watchCount }} 次观看</span>
        </div>
        <span style="padding: 20px 0">{{ video.introduction }}</span>

      </div>
    </div>

    <el-divider/>

    <el-collapse-transition>
      <!--搜索框-->
      <TableSearch v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-form :model="queryParams" ref="queryForm" label-suffix=":" label-width="100px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item prop="title" label="标题">
                <el-input v-model="queryParams.title" placeholder="请输入标题" clearable/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

      </TableSearch>
    </el-collapse-transition>

    <div>
      <el-button style="width: 80px" size="mini" type="success" @click="handleSave">添加</el-button>
      <el-button style="width: 80px" size="mini" type="primary" @click="showSearch = !showSearch">
        {{ showSearch ? '隐藏搜索' : '显示搜索' }}
      </el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="title" label="标题" width="400"/>

        <el-table-column prop="url" label="视频链接" show-overflow-tooltip/>

        <el-table-column prop="sort" label="排序" width="200"/>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button type="text" @click="handleView(scope.row)">查看视频</el-button>
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--新增、编辑弹框-->
    <CommonDialog ref="dialog" :title="dialogInfo.title" :type="dialogInfo.type" :disabled="dialogInfo.disabled"
                  @confirm="handleSubmitConfirm" @close="handleClose" top="100" width="450px">
      <div style="width: 100%">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">

          <el-form-item label="" prop="id">
            <el-input v-show="false" v-model="formData.id"></el-input>
          </el-form-item>

          <el-form-item label="标题" prop="title">
            <el-input v-model="formData.title" placeholder="请输入标题" clearable/>
          </el-form-item>

          <el-form-item label="视频链接" prop="url">
            <el-input v-model="formData.url" placeholder="请输入视频链接" type="textarea" maxlength="200"
                      show-word-limit rows="6" clearable/>
          </el-form-item>

          <el-form-item label="videoId" prop="videoId" v-show="false"></el-form-item>
          <el-form-item label="vid" prop="vid" v-show="false"></el-form-item>

          <el-form-item label="排序" prop="sort">
            <el-input v-model="formData.sort" min="1" max="99999" type="number" placeholder="请输入排序"/>
          </el-form-item>

        </el-form>
      </div>
    </CommonDialog>

  </div>
</template>

<script>
import TableSearch from "@/components/TableSearch/TableSearch.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import store from "@/store";
import {validateEMail} from "@/utils/validate";

export default {
  name: "index",
  components: {CommonDialog, Pagination, TableSearch},
  data() {
    return {
      queryParams: {
        title: ''
      },
      tableData: [],
      showSearch: false,
      formData: {
        id: null,
        title: '',
        url: '',
        sort: 99,
        videoId: null,
        vid: '',
      },

      token: store.state.userinfo.token,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
      innerHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 1, max: 50, message: '1~100字符', trigger: 'blur'}
        ],
        url: [
          {required: true, trigger: 'blur', validator: this.validateVideoUrl},
        ]
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
      previewList: [],
      video: {},
    }
  },

  mounted() {
    this.getVideo(this.$route.params.id)
  },

  methods: {

    getVideo(id = null) {
      if (!id)
        return

      this.$api.video.get(id).then(res => {
        this.video = res
        this.getList(id)
      })
    },

    getList(id = null) {
      if (!id)
        return

      this.$api.videoDetail.getByVideoId(id).then(res => {
        this.tableData = res
      })
    },

    resetFormData() {
      this.$resetForm('form')
    },

    handleSave() {
      this.dialogInfo.title = '新增'
      this.dialogInfo.type = 'success'
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
    },

    validateVideoUrl(rule, value, callback) {
      if (value == '' || value == undefined || value == null) {
        callback(new Error('请输入视频链接'));
      }

      let queryString = value.split("?")[1];
      let params = queryString.split("&");
      let vid = null;
      for (let i = 0; i < params.length; i++) {
        let param = params[i].split("=");

        if (param[0] === "vid") {
          vid = param[1];
          break;
        } else if (param[0] === "cut_vid") {
          vid = param[1];
          break;
        }
      }
      if (vid) {
        this.formData.vid = vid
        callback();
      } else {
        callback(new Error('此视频链接解析失败，请联系管理员'));
      }
    },

    handleView(video) {
      if (!video.url) {
        return
      }
      window.open(video.url)
    },

    handleUpdate(row) {
      this.$api.videoDetail.get(row.id).then(res => {
        this.dialogInfo.title = '修改'
        this.dialogInfo.type = 'warning'
        this.$refs.dialog.show()
        this.$nextTick(() => {
          this.formData = res
        })
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除 “ <b class="color-danger">${row.title}</b> ”？`
      }).then(() => {
        this.$api.videoDetail.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSubmitConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.$api.videoDetail.update(this.formData).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.videoDetail.save(this.formData).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleImageSuccess(res, file) {
      if (Number(res.code) !== 200) {
        this.$message.error('上传失败，请联系管理员')
        return
      }
      this.formData.coverFileId = res.data.id
      this.formData.cover = res.data.url;
    },

    handleImageDelete() {
      this.formData.cover = ''
      this.formData.coverFileId = null
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt6M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt6M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt6M;
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
  }
}
</script>

<style scoped>

</style>
